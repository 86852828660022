/******section-3-css start****/

  .Nsstiles h6{
    font-weight: 800;
    color: #0A266C;
    font-size: 16px;
    font-style: normal;
    margin: 20px 0 0 0 !important;
  }
  .Nsstiles p{
    margin: 10px 0 0 0 !important;
  }
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  

.nssbox.MuiCardContent-root{
  padding: 0px !important;
}
.ecbox h5{
  font-size: 28px !important;
}
 @media screen and (max-width:767px) {
  .transionpage-item{
    padding-right: 32px;
  }
  .ecbox h5{
    font-size: 22px !important;
  }
 }
/******section-3-css end****/