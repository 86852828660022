iframe > .top-nav-bar, .title-top, .Api-footer  {
    display: none !important;
}
.macro-iframe{
    margin: 20px 0;
}

.tabpanel{
    padding: 0px 0 !important;
}
.MuiTab-textColorPrimary{
    font-weight: 600 !important;
}
.MuiTab-textColorPrimary.Mui-selected{
    color: #0A266C !important;
}
.MuiTabs-indicator{
    background: #0A266C !important;
}
ul.gridtable {
    font-size: 16px;
    list-style-type: none;
    margin-bottom: 15px;
    padding: 0 50px;
    line-height: 2;
    background: #ffffff;
}
ul.gridtable>li {
    color: #333;
    padding: 0;
}
.innerpage-body {
    min-height: calc(100vh - 167px);
}

.fullwidth{
    width: 100% !important;
    padding-left: 0px;
    padding-right: 0px;
}
.fullwidth .MuiBox-root{
    padding-left: 50px;
    padding-right: 50px;
}
.macro-title{
    float: right;
    padding-right: 50px;
}
.macro-embed iframe{
    height: 780px;
}
.nmdslink{
    color: #333333;
}
.nmdslink:hover{
    color: #0A266C;
}
.nmdslink svg{
    color: #333333;
    fill: #333333;
    width: 16px;
}
.nmdslink:hover svg{
    color: #0A266C;;
    fill: #0A266C;
}
.tablist button{
    padding: 8px 16px !important;
}
.dataviz-wrapper{
    padding-top: 8px;
}

@media screen and (max-width:767px) {
    .macro-embed iframe{
        height: calc(100vh - 410px);
    }

    .fullwidth{
        width: 100% !important;
        padding-left: 15px;
        padding-right: 15px;
    }
    .fullwidth .MuiBox-root{
        padding-left: 0px;
        padding-right: 0px;
    }
    .macro-title{
        float: none;
        padding-right: 0px;
        margin-bottom: 0px !important;
    }
    ul.gridtable {

        padding: 0 0px;

    }
    .tablist button{
        padding: 8px 8px !important;
    }

    .swagger-ui .opblock-tag{
        flex-direction: column;
    }
    .css-13omgl2{
        width: 100% !important;
    }



  }


  /******React-dashboard-start***********/

.dashbord-filters .MuiAutocomplete-root, .dashbord-filters .MuiFormControl-root{
    margin-top: 10px !important;
    /* background:#E9F5FB !important; */
}
.dashbord-filters .MuiAutocomplete-root label, .dashbord-filters .MuiFormControl-root .MuiFormLabel-root {
    color:#0a266c !important;
    font-size: 13px !important;
    font-weight: bold;
}
.dashbord-filters .MuiFormControl-root label{
    transform: translate(8px, 16px) scale(1) !important;
}
 .dashbord-filters .MuiFormControl-root .MuiFormLabel-filled{
    transform: translate(8px, -8px) scale(0.75) !important;
}
.dashbord-filters .MuiChip-filled{
    background:#1565c0 !important;
    color:#fff !important;
}
.dashbord-filters .MuiChip-deleteIcon{
    color:#fff !important;
}
.dashbord-filters .autocompleteContainer .MuiAutocomplete-tag {
   margin: 14px 0px 0px 3px !important;
}
.dashbord-filters .autocompleteContainer .MuiInputLabel-root {
    margin-top: 10px !important;
}
.dashbord-filters .autocompleteContainer > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
    position: initial;
    white-space: nowrap;
    flex-wrap: "nowrap";
    overflow-x: "auto";
    scrollbar-width: thin;
    padding-bottom: "10px";
    max-width: "83% !important";
    margin-bottom:10px !important;
    
}
.dashbord-filters .listStyle {
    align-items: start !important;
}
.dashbord-filters .MuiAutocomplete-endAdornment{
    top: calc(50% - 8px) !important;
    right: 6px !important;
}

.dashbord-filters .MuiIconButton-root, .data-table-header .MuiIconButton-root{
    min-width: 1em !important;
}
.data-table-header .MuiToolbar-gutters{
    padding-left: 0px !important;
}
.mospi-table{
    margin-top: 20px;
}

.datatable-wrapper{
    min-height: calc(100vh - 244px);
}
.data-table-header{
    float: right;
}
.macrodownloadbtn{
    padding: 4px 4px !important;
    min-height: 46px !important;
    border-radius: 50% !important;
    min-width: 46px !important;
}
.macrodownloadbtn img{
    width: 30px !important;
}
.tabletabs button{
    padding: 8px 16px !important;
}
.mospi-table table tr td{
    padding: 2px 16px !important;
    /* white-space: normal !important; */
}

 .dashbord-filters .MuiAutocomplete-inputRoot, .dashbord-filters .MuiSelect-select{
    white-space: nowrap;
    -webkit-box-flex-wrap: nowrap  !important;
    -webkit-flex-wrap: nowrap  !important;
    -ms-flex-wrap: nowrap  !important;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    scrollbar-width: thin;
    max-width: 100% !important;
    margin-bottom: 0px !important;
    position: initial !important;

 }
 .filter-sidebar .MuiIconButton-root, .filter-mobile-sidebar .MuiIconButton-root{
    /* display: flex !important; */
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 2px 2px !important;
 }
 .filter-sidebar .MuiAutocomplete-popupIndicator, .filter-mobile-sidebar .MuiAutocomplete-clearIndicator{
    padding: 0px 0px !important;
    color: #0a266c;
 }

 .grouphead{
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row !important;
    margin-bottom: 0px !important;
    padding: 10px;
    background: #0A266C;
    color: #ffffff;
    border-bottom: 1px solid #fff;
 }
 .groupheader {

    margin-bottom: 0px !important;
 }
 .MuiTableCell-head{
    background: #0A266C !important;
 }
 .grouped-table tr td:first-child{
    width: 70%;
 }
 .mospi-table table tr td:last-child{
    text-align: right;
 }

 .mospi-table table tr th:last-child{
    text-align: right;
 }

 .mospi-table table tr:nth-of-type(odd) {
    background: #ffffff;
  }
  .mospi-table table tr:nth-of-type(even) {
    background: #D5E7F0;
  }
   .MuiTableCell-head{
    background: #0A266C !important;
 }

 .MuiRadio-root{
    max-width: 40px !important;

 }

 .datatable-body .MuiFormControlLabel-label span{
    color: rgb(38, 39, 48) !important;
 }
 .datatable-body p, .datatable-body span{
    color: rgb(38, 39, 48) !important;
    font-size: 15px;
 }
 .parameter-data {
    font-size: 15px;
    background: #0a266c26;
    border-left: 5px solid #0a266c;
    margin: 20px 0 10px 0;
    padding: 2px 0 3px 10px;
    color: rgb(38, 39, 48);
}
.mospi-table table tr td{
    border: 1px solid #ffffff !important;
}


  
  /* Default state: truncate text with ellipsis */
.cell-content p, .MuiTable-root p{
    white-space: nowrap; /* Keep text on a single line */
    overflow: hidden;    /* Hide the overflowed text */
    text-overflow: ellipsis; /* Show ellipsis when text overflows */
    max-width: 450px;     /* Limit width of the cell */
  }
  
  /* On hover, show full text */
  .row-hover:hover .cell-content p {
    white-space: normal;   /* Allow text to wrap and show fully */
    overflow: visible;     /* Allow full text to be visible */
    text-overflow: unset;  /* Remove ellipsis */
  }
  
  .filter-sidebar .MuiSelect-select, .filter-mobile-sidebar .MuiSelect-select{
    padding: 12px 35px 4px 8px !important;
    border-radius: 0px !important;
    
  }
  .filter-sidebar .MuiInputBase-root fieldset, .filter-mobile-sidebar .MuiInputBase-root fieldset{
    border: 0px solid #0a266c !important;
    border-bottom: 1px solid #0a266c !important;
    border-radius: 0px !important;
  }
  .filter-sidebar .MuiInputBase-root fieldset span, .filter-mobile-sidebar .MuiInputBase-root fieldset span{
    color: #0a266c !important;
    font-size: 0.5rem ;
  }
  .dashbord-filters .MuiAutocomplete-popper .MuiAutocomplete-listbox, .dashbord-filters  .MuiMenu-list{
    font-size: 0.5rem ; 
  }
/* Remove padding for listbox */
.dashbord-filters .MuiAutocomplete-listbox .MuiAutocomplete-option, .dashbord-filters  .MuiMenu-list .MuiMenuItem-root {
    padding: 0 10px !important; /* Remove padding from list item */
  }
  
  /* Remove padding for checkboxes */
  .dashbord-filters .MuiAutocomplete-option .MuiCheckbox-root, .dashbord-filters  .MuiMenu-list .MuiCheckbox-root {
    padding: 0 !important;
  }
/* Reduce radio button size */
.dashbord-filters .radio-button, .dashbord-filters .radio-button .MuiSvgIcon-root {
    transform: scale(0.8);  /* Adjust the scale to resize */
  }
   .MuiPopover-root .MuiPaper-root .MuiMenuItem-root{
    border-bottom: 1px solid #e0e0e0;
  }
/******React-dashboard-end***********/

/*********Viz-Start***********/

.vizBtn{
    display: flex;
    gap: 10px;
}

.vizBtn button{

    background: #e7e7e7;
    border: 1px solid #9a9a9a;
    border-radius: 4px;
    padding: 4px !important;
    min-width: 30px !important;
    max-height: 30px !important;
    line-height: .5;
}