/******section-3-css start****/

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

.nssbox.MuiCardContent-root{
  padding: 0px !important;
}
.ecbox h5{
  font-size: 28px !important;
}
 @media screen and (max-width:767px) {
  .transionpage-item{
    padding-right: 32px;
  }
  .ecbox h5{
    font-size: 22px !important;
  }
 }
/******section-3-css end****/